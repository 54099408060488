import React from "react";

const PlayButtonIcon = () => {
  return (
    <svg
      width="58px"
      height="58px"
      viewBox="0 0 58 58"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="filter_1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
        </filter>
      </defs>
      <g id="play-button" filter="url(#filter_1)">
        <path
          d="M28.672 0C12.8613 0 0 12.8625 0 28.672C0 44.4815 12.8613 57.344 28.672 57.344C44.4827 57.344 57.344 44.4815 57.344 28.672C57.344 12.8625 44.4827 0 28.672 0ZM40.0704 29.6765L23.3451 40.4285C23.1491 40.5556 22.9227 40.6187 22.6987 40.6187C22.5027 40.6187 22.3044 40.5696 22.1271 40.4729C21.742 40.2629 21.504 39.8615 21.504 39.424L21.504 17.92C21.504 17.4825 21.742 17.0811 22.1271 16.8711C22.5051 16.6635 22.9787 16.6762 23.3451 16.9155L40.0704 27.6675C40.4111 27.8868 40.6187 28.266 40.6187 28.672C40.6187 29.078 40.4111 29.4571 40.0704 29.6765Z"
          id="Shape"
          fill="#000000"
          stroke="none"
        />
      </g>
    </svg>
  );
};

export default PlayButtonIcon;
